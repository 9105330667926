import { lazy } from 'react'

export const Dashboard = lazy(() => import("./dashboard"))
export const Admin = lazy(() => import("./admin"))
export const Commerce = lazy(() => import("./commerce"))
export const AddCommerce = lazy(() => import("./commerce/Add"))
export const EditCommerce = lazy(() => import("./commerce/Edit"))
export const Mpos = lazy(() => import("./mpos"))
export const AddMpos = lazy(() => import("./mpos/Add"))
export const EditMpos = lazy(() => import("./mpos/Edit"))
export const Device = lazy(() => import("./device"))
export const AddDevice = lazy(() => import("./device/Add"))
export const EditDevice = lazy(() => import("./device/Edit"))
export const Users = lazy(() => import("./users"))
export const AddUser = lazy(() => import("./users/Add"))
export const EditUser = lazy(() => import("./users/Edit"))
export const Reports = lazy(() => import("./reports"))
export const ParamsReport = lazy(() => import("./reports/ParamsReport"))
export const Roles = lazy(() => import("./admin/Roles"))
export const RolesDetail = lazy(() => import("./admin/RolDetail"))
export const AdminReports = lazy(() => import("./admin/Reports"))
export const EconomicActivity = lazy(() => import("./admin/EconomicActivity"))
export const BulkCommerces = lazy(() => import("./admin/BulkCommerces"))
export const BulkDisableCommerces = lazy(() => import("./admin/BulkDisableCommerces"))
export const BulkBines = lazy(() => import("./admin/BulkBines"))
export const Parameters = lazy(() => import("./admin/Parameters"))
export const NoMatch = lazy(() => import("../components/NoMatch"))
export const Home = lazy(() => import("./home"))
export const Transactions = lazy(() => import("./transactions"))
export const TransactionsConsole = lazy(() => import("./transactionsConsole"))
export const Tickets = lazy(() => import("./tickets"))
export const Logs = lazy(() => import("./admin/Logs"))
export const Jobs = lazy(() => import("./admin/Jobs"))

//TODO: Agregar opción para ver las transacciones
