import React from 'react'
import PropTypes from 'prop-types'


const Logo = props => {
  const { height, logoname } = props
  var name = "https://blobstoragewebsite.blob.core.windows.net/web-site/" + logoname
  return (
    <a {...props} href='/'>
      <img style={{ height }} src={name} alt='Logo' />
    </a>
  )
}

Logo.propTypes = {
  height: PropTypes.number,
  logoname: PropTypes.string
}

export default Logo
