import React, { Suspense, useEffect, useContext, useCallback } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import { createBrowserHistory } from "history"
import AAD_B2CService from '../settings/azure'
import Loading from '../components/Loading'
import * as containers from './index'
import { StoreContext } from '../settings/context'

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const hist = createBrowserHistory()

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


class AppClass extends React.Component {
  static contextType = StoreContext
  static propTypes = {
    customer: PropTypes.string,
    user: PropTypes.string,
    handleLogout: PropTypes.func,
    handleCloseSnackbar: PropTypes.func,
    state: PropTypes.object,
  }

  constructor() {
    super();
    this.state = {
      listMenu: [],
      errorRole: false,
      loading: false,
      economicActivityList: [],
      typeList: [],
      netProcessingList: [],
      statusList: [],
      locationList: []
    };
  }

  loadAllEconomicActivities = () => {
    const contextData = this.context
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/EconomicActivity/all/` + this.props.customer)
      .then(res => res.json())
      .then((data) => {
        this.setState({ economicActivityList: data })
        contextData.setCommerceData({
          economicActivityList: data,
          typeList: this.state.typeList,
          statusList: this.state.statusList,
          netProcessingList: this.state.netProcessingList,
          locationList: this.state.locationList
        })
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
      })
      .catch(error => {
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
        console.error('Error:', error)
      })
  }

  loadAllTypes = () => {
    const contextData = this.context
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/Type/all/` + this.props.customer)
      .then(res => res.json())
      .then((data) => {
        this.setState({ typeList: data })
        this.loadAllEconomicActivities()
      })
      .catch(error => {
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
        console.error('Error:', error)
      })
  }

  loadLocations = () => {
    const contextData = this.context
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/Location/all/` + this.props.customer)
      .then(res => res.json())
      .then((data) => {
        this.setState({ locationList: data })
        this.loadAllTypes()
      })
      .catch(error => {
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
        console.error('Error:', error)
      })
  }

  loadAllNetProcessing = () => {
    const contextData = this.context
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/NetProcessing/All/` + this.props.customer)
      .then(res => res.json())
      .then((data) => {
        this.setState({
          netProcessingList: this.props.customer === "BANESCO" ?
            data.filter(dd => dd.name.toLowerCase().includes("nativa") ||
              dd.name.toLowerCase().includes("banesco")) : data
        })
        this.loadLocations()
      })
      .catch(error => {
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
        console.error('Error:', error)
      })
  }

  loadCommerce = () => {
    const contextData = this.context
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/Commerce/All/` + this.props.customer)
      .then(res => res.json())
      .then((data) => {
        contextData.setUserData({
          statusList: this.state.statusList,
          commerceList: data
        })
        //Aqui
        // contextData.setLoadingData({
        //   loading: false
        // })
        // this.setState({ loading: false })
        this.loadAllNetProcessing()
      })
      .catch(error => {
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
        console.error('Error:', error)
      })
  }

  loadAllRoles = (commerce, role) => {
    const contextData = this.context
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/Role/All/` + this.props.customer)
      .then(res => res.json())
      .then((data) => {
        if (this.props.customer === "BANESCO" && !role.name.toLowerCase().includes("super usuario")) {
          if (role.name.toLowerCase().includes("seguridad banco")) {
            contextData.setUserRoleData({
              roleList: data.filter(d => d.name.toLowerCase().includes("banco") && d.commerceId === null)
            })
          }
          else if (role.name.toLowerCase().includes("banco")) {
            contextData.setUserRoleData({
              roleList: data.filter(d => d.name.toLowerCase().includes("comercio") && d.commerceId === null)
            })
          }
          else if (role.name.toLowerCase().includes("comercio")) {
            contextData.setUserRoleData({
              roleList: data.filter(d => d.commerceId === commerce.commerceId ||
                (d.name.toLowerCase().includes("comercio") && d.commerceId === null))
            })
          }
          else {
            contextData.setUserRoleData({
              roleList: data
            })
          }
        }
        else {
          contextData.setUserRoleData({
            roleList: data
          })
        }
        this.loadStatuses()
      })
      .catch(error => {
        console.error('Error:', error)
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
      })
  }

  loadStatuses = () => {
    const contextData = this.context
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/status/all/` + this.props.customer)
      .then(res => res.json())
      .then((data) => {
        this.setState({ statusList: data })
        this.loadCommerce()
        //this.loadAllNetProcessing()
      })
      .catch(error => {
        contextData.setLoadingData({
          loading: false
        })
        this.setState({ loading: false })
        console.error('Error:', error)
      })
  }

  componentDidMount() {
    const contextData = this.context
    contextData.setLoadingData({
      loading: true
    })
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/Admin/` + this.props.customer + "/" + this.props.user + "/Rutas web")
      .then(res => res.json())
      .then((data) => {
        if (data.length === 0) {
          this.setState({ loading: false, errorRole: true })
          setTimeout(
            function () {
              this.setState({ errorRole: false })
              this.props.handleLogout()
            }
              .bind(this),
            5000
          );
        }
        else {
          const listMenu = data.map((item, index) => {
            let menu = JSON.parse(item.component)
            menu.component = containers[menu.component]
            if (data.length === 1 && index === 0 && menu.path !== "/") {
              menu.path = "/"
            }
            if (data.length > 0 && index === 0 && menu.path !== "/") {
              menu.path = "/"
              menu.menuExact = true
            }
            return menu
          })
          this.setState({ listMenu: listMenu })
          fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/User/ListRoleByLogin/` +
            this.props.customer + "/" + this.props.user)
            .then(res => res.json())
            .then((response) => {
              contextData.setRole(response)
              fetch(`${process.env.REACT_APP_BACKEND}${process.env.REACT_APP_BACKEND_PREFIX}/User/ListCommerceByLogin/` +
                this.props.customer + "/" + this.props.user)
                .then(res => res.json())
                .then((responseCommerce) => {
                  contextData.setCommerce(responseCommerce)
                  this.loadAllRoles(responseCommerce, response)
                })
                .catch(error => {
                  contextData.setLoadingData({
                    loading: false
                  })
                  console.error('Error:', error)
                  this.setState({ loading: false })
                })
            })
            .catch(error => {
              contextData.setLoadingData({
                loading: false
              })
              console.error('Error:', error)
              this.setState({ loading: false })
            })
        }
      })
      .catch(error => {
        contextData.setLoadingData({
          loading: false
        })
        console.error('Error:', error)
        this.setState({ loading: false })
      })
  }

  componentWillUnmount = () => {
  }

  render() {
    const { listMenu } = this.state
    //if (this.state.loading) return <Loading />
    return (
      <>
        {this.state.loading &&
          <Loading />
        }
        <Router history={hist}>
          <Layout
            listMenu={listMenu.filter(item => !item.hideSidebar)}
            handleLogout={this.props.handleLogout}
            handleCloseSnackbar={this.props.handleCloseSnackbar}
            snackbar={this.props.state.snackbar}
            user={this.props.user}
            customer={this.props.customer}
          >
            <Suspense fallback={<Loading />}>
              <Switch>
                {listMenu.map((item, index) => (
                  <Route key={index} exact={item.exact} path={item.path} component={item.component} />
                ))}
                <Route key={-1} exact={true} path="/tickets/:id" component={containers["Tickets"]} />
                <Route component={containers.NoMatch} />
              </Switch>
            </Suspense>
          </Layout>
        </Router>

        <Dialog aria-labelledby="customized-dialog-title" open={this.state.errorRole}>
          <DialogTitle id="customized-dialog-title">
            {"Alerta"}
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              {"El usuario no cuenta con ningun rol asignado"}
            </Typography>
          </DialogContent>
        </Dialog>
      </>
    )
  }
}

function App() {
  const { state, dispatch, setLogin, setCustomer } = useContext(StoreContext)
  const AAD_B2CServiceI = new AAD_B2CService()

  const didMount = async () => {
    const user = AAD_B2CServiceI.getUser()
    setLogin(JSON.stringify(user))
    setCustomer(AAD_B2CServiceI.getCustomer())
  }

  useEffect(() => { didMount() }, [])
  const handleLogout = useCallback(() => { AAD_B2CServiceI.logout() })
  const handleCloseSnackbar = useCallback(() => { dispatch({ type: 'snackClose' }) })

  return (
    <AppClass user={AAD_B2CServiceI.getUser().emails}
      customer={AAD_B2CServiceI.getCustomer()}
      state={state}
      handleLogout={handleLogout}
      handleCloseSnackbar={handleCloseSnackbar}
    />
  );
}

export default App;